import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  AiOutlineArrowRight,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle
} from 'react-icons/ai';
import { FiAlertCircle } from 'react-icons/fi';
import VisitTypeInfos from './VisitTypeInfos';
import { navigate } from '../../../.cache/gatsby-browser-entry';
import { useHandleResize } from '../../utils';
import PricesInfosModal from './PricesInfosModal';
import { ClockFilterIcon } from '../../assets/customIcons';
import NbVisitorAlert from './NbVisitorAlert';
import VisitorsManager from './VisitorsManager';

const FiltersCard = ({
  t,
  state,
  dispatch,
  onHandleLanguageChange,
  addNbVisitors,
  subtractNbVisitors,
  onValidateFilters,
  onHandleVisitTypeChange,
  onCancel
}) => {
  const { width } = useHandleResize();

  const openPricesModal = () => {
    dispatch({ pricesModal: true });
  };

  const [visitTypeList, setVisitTypeList] = useState([
    {
      title: 'intermezzo',
      key: 'IM'
    },
    {
      title: 'belle_epoque',
      key: 'BE'
    },
    {
      title: 'work_of_art',
      key: 'SC'
    },
    {
      title: 'visit_surprise',
      key: '17'
    }
  ]);

  useEffect(() => {
    // Spanish
    if (state.language === 'E') {
      setVisitTypeList([
        {
          title: 'intermezzo',
          key: 'IM'
        }
      ]);
    }
    // English
    else if (state.language === 'G') {
      setVisitTypeList([
        {
          title: 'intermezzo',
          key: 'IM'
        },
        {
          title: 'visit_surprise',
          key: '17'
        }
      ]);
    } else {
      setVisitTypeList([
        {
          title: 'intermezzo',
          key: 'IM'
        },
        {
          title: 'belle_epoque',
          key: 'BE'
        },
        {
          title: 'work_of_art',
          key: 'SC'
        },
        {
          title: 'visit_surprise',
          key: '17'
        }
      ]);
    }
  }, [state.language]);

  const openModalLimitVisitors = () => {
    dispatch({ limitVisitors: true });
  };

  return (
    <>
      <NbVisitorAlert t={t} visible={state.limitVisitors} dispatch={dispatch} />
      <Grid container>
        <Grid item xs={8}>
          <Card className="filterCard">
            <CardContent>
              {state.pricesModal && (
                <PricesInfosModal state={state} dispatch={dispatch} />
              )}
              <Grid container>
                <Grid item xs={6}>
                  <p>
                    {t('reservationPage.languageChoice.title1')}
                    <span>{t('reservationPage.languageChoice.title2')}</span>
                    {state.errors &&
                      state.errors.find((el) => el === 'language') && (
                        <FiAlertCircle
                          style={{ color: 'red', marginLeft: 16 }}
                        />
                      )}
                  </p>

                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant={state.language === 'F' ? 'contained' : ''}
                      onClick={() => onHandleLanguageChange('F')}
                    >
                      {t('reservationPage.languageChoice.french')}
                    </Button>
                    <Button
                      variant={state.language === 'G' ? 'contained' : ''}
                      onClick={() => onHandleLanguageChange('G')}
                    >
                      {t('reservationPage.languageChoice.english')}
                    </Button>
                    <Button
                      variant={state.language === 'E' ? 'contained' : ''}
                      onClick={() => onHandleLanguageChange('E')}
                    >
                      {t('reservationPage.languageChoice.spanish')}
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={6}>
                  <p>
                    {t('reservationPage.nbPersons.title1')}
                    <span>{t('reservationPage.nbPersons.title2')}</span>
                    {state.errors &&
                      state.errors.find((el) => el === 'visitorsNb') && (
                        <FiAlertCircle
                          style={{ color: 'red', marginLeft: 16 }}
                        />
                      )}
                  </p>
                  <Grid container>
                    {state.visitType === '17' ? (
                      <Grid item xs={6}>
                        <p
                          style={{
                            marginBottom: 8,
                            textTransform: 'uppercase',
                            fontWeight: 700
                          }}
                        >
                          {t('reservationPage.nbPersons.unique_price')}
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            disabled={state.full_price === 0}
                            onClick={() => subtractNbVisitors('full_price')}
                          >
                            <AiOutlineMinusCircle />
                          </IconButton>
                          <Input
                            style={{ width: 30 }}
                            value={
                              state.full_price !== undefined && state.full_price
                            }
                            disableUnderline
                          />
                          <IconButton
                            style={{
                              opacity: state.totalVisitor === 19 ? 0.5 : 1
                            }}
                            onClick={() =>
                              state.totalVisitor === 19
                                ? openModalLimitVisitors()
                                : addNbVisitors('full_price')
                            }
                          >
                            <AiOutlinePlusCircle />
                          </IconButton>
                        </div>
                        <p
                          style={{
                            fontSize: 14,
                            margin: '6px 0 0 50px',
                            fontWeight: 300
                          }}
                        >
                          {state.visitType === '17'
                            ? t('landingPage.infocard.evening-price')
                            : t('landingPage.infocard.visitor.full_price')}
                        </p>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={3}>
                          <VisitorsManager
                            purpose="full_price"
                            t={t}
                            openModalLimitVisitors={openModalLimitVisitors}
                            subtractNbVisitors={subtractNbVisitors}
                            addNbVisitors={addNbVisitors}
                            dispatch={dispatch}
                            state={state}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <VisitorsManager
                            purpose="reduced_price"
                            t={t}
                            openModalLimitVisitors={openModalLimitVisitors}
                            subtractNbVisitors={subtractNbVisitors}
                            addNbVisitors={addNbVisitors}
                            dispatch={dispatch}
                            state={state}
                          />
                          {/* <p style={{ fontSize: 11, margin: '4px 0 0 4px', fontWeight: 300, fontStyle: 'italic' }}>{t('reservationPage.nbPersons.reduced_price_details')}</p>*/}
                        </Grid>
                        <Grid item xs={3}>
                          <VisitorsManager
                            purpose="child_price"
                            t={t}
                            openModalLimitVisitors={openModalLimitVisitors}
                            subtractNbVisitors={subtractNbVisitors}
                            addNbVisitors={addNbVisitors}
                            dispatch={dispatch}
                            state={state}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <VisitorsManager
                            purpose="free_price"
                            t={t}
                            openModalLimitVisitors={openModalLimitVisitors}
                            subtractNbVisitors={subtractNbVisitors}
                            addNbVisitors={addNbVisitors}
                            dispatch={dispatch}
                            state={state}
                          />
                          {/* <p style={{ fontSize: 12, margin: '6px 0 0 4px', fontWeight: 300 }}>{t('reservationPage.nbPersons.child-age')}</p>*/}
                          {/* <p style={{ fontSize: 11, margin: '4px 0 0 4px', fontWeight: 300, fontStyle: 'italic' }}>{t('reservationPage.nbPersons.child-note')}</p>*/}
                        </Grid>
                        {width <= 1024 && (
                          <Button
                            style={{ marginTop: 16, padding: '6px 14px' }}
                            onClick={openPricesModal}
                          >
                            {t('buttons.pricesModal')}
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <p style={{ position: 'relative' }}>
                    {t('reservationPage.visitType.title1')}
                    <span>{t('reservationPage.visitType.title2')}</span>
                    {state.errors &&
                      state.errors.find((el) => el === 'visitType') && (
                        <FiAlertCircle
                          style={{ color: 'red', marginLeft: 16 }}
                        />
                      )}
                    <span className="filtercard-time">
                      {state.visitType === '17'
                        ? t('landingPage.infocard.time_night')
                        : t('landingPage.infocard.time')}{' '}
                      <ClockFilterIcon />
                    </span>
                  </p>
                  <FormControl>
                    {/* {calendarState && (
                        <InputLabel
                            style={{color: '#fff'}}
                            id="demo-simple-select-label">{t('reservationPage.selectMonth.title')}
                        </InputLabel>
                    )}*/}
                    <InputLabel
                      style={{ color: '#fff' }}
                      id="demo-simple-select-label"
                    >
                      {t('reservationPage.visitType.placeholder')}
                    </InputLabel>
                    <Select
                      style={{ color: '#fff', minWidth: 225 }}
                      labelId="demo-simple-select-standard-label"
                      value={state.visitType}
                      onChange={(event) =>
                        onHandleVisitTypeChange(event.target.value)
                      }
                    >
                      {visitTypeList.map((type) => (
                        <MenuItem key={type.key} value={type.key}>
                          {t(`reservationPage.visitType.${type.title}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/*   <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant={state.visitType === "IM" ? "contained" : ""}
                      onClick={() => onHandleVisitTypeChange("IM")}
                    >
                      {t("reservationPage.visitType.intermezzo")}
                    </Button>
                    <Button
                      variant={state.visitType === "BE" ? "contained" : ""}
                      onClick={() => onHandleVisitTypeChange("BE")}
                    >
                      {t("reservationPage.visitType.belle_epoque")}
                    </Button>
                    <Button variant={state.visitType === 'OE' ? "contained" : ''} onClick={() => onHandleVisitTypeChange('SC')}>{t('reservationPage.visitType.work_of_art')}</Button>
                    <Button
                      variant={state.visitType === "17" ? "contained" : ""}
                      onClick={() => onHandleVisitTypeChange("17")}
                    >
                      {t("reservationPage.visitType.visit_surprise")}
                    </Button>
                  </ButtonGroup>*/}
                </Grid>
                <Grid item xs={8}>
                  <p
                    className="reservation-group-link"
                    onClick={() => navigate('/reservation-groupe/')}
                  >
                    {t('reservationPage.group')}
                    <IconButton
                      onClick={() => navigate('/reservation-groupe/')}
                    >
                      <AiOutlineArrowRight />
                    </IconButton>
                  </p>
                </Grid>
                {/* <Grid item xs={5}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
                                        <Step1NightIcon />
                                        <p style={{ fontSize: 14, fontStyle: 'italic', width: 150, margin: '0 0 0 10px' }}>{t('reservationPage.night_price')}</p>
                                    </div>
                                </Grid> */}
                {/* <Grid item xs={6}>
                                    <p>
                                        {t('reservationPage.selectMonth.title1')}
                                        <span className="title-underline">{t('reservationPage.selectMonth.title2')}</span>
                                    </p>
                                    <SelectMonth />
                                </Grid>*/}
              </Grid>

              <Grid container>
                <Grid item xs={4}>
                  <div style={{ float: 'right' }}>
                    <Button
                      className="link-btn"
                      onClick={() => onCancel()}
                      style={{ marginRight: 10 }}
                    >
                      {t('buttons.cancel')}
                    </Button>
                    <Button color="primary" onClick={() => onValidateFilters()}>
                      {t('buttons.validate')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          {state.visitType && (
            <VisitTypeInfos
              t={t}
              type={state.visitType}
              state={state}
              dispatch={dispatch}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersCard;

FiltersCard.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.shape({
    language: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    limitVisitors: PropTypes.bool,
    pricesModal: PropTypes.bool,
    visitType: PropTypes.string,
    full_price: PropTypes.number,
    totalVisitor: PropTypes.number
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  onHandleLanguageChange: PropTypes.func.isRequired,
  addNbVisitors: PropTypes.func.isRequired,
  subtractNbVisitors: PropTypes.func.isRequired,
  onValidateFilters: PropTypes.func.isRequired,
  onHandleVisitTypeChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
